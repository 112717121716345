<template>
  <div class="content-block dx-card responsive-paddings">
   <DxDataGrid
     id="grid"
     :ref="gridRefName"
     :data-source="dataSource"
     :column-auto-width="true"
     :show-borders="true"
     :show-column-lines="true"
     @exporting="onExporting"
     @toolbar-preparing="onToolbarPreparing($event)">
     
     <DxSearchPanel :visible="true"/>
     <DxHeaderFilter :visible="true"/>
     <DxScrolling mode="virtual"/>
     <DxSorting mode="multiple"/>
     <DxExport :enabled="true"/>

     <DxColumn
        data-field="USER_ID"
        caption="심사위원"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="TESTER_NAME"
        caption="참가자"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        v-for="(item, index) in examItemList"
        :data-field="item.item"   
        :key="'item' + (index + 1)"
        :caption="item.item"
        :header-filter="{ allowSearch: true }"
        dataType="number">
      </DxColumn>

      <DxColumn
        data-field="TOTAL"
        caption="총합"
        :header-filter="{ allowSearch: true }"
        dataType="number">
      </DxColumn>  

     <template #examList-template>
       <DxSelectBox
         width="300"
         :items="examList"
         display-expr="groupNameId"
         value-expr="groupId"
         value="ALL"
         @selection-changed="onSelectedExam"/>
     </template>
   </DxDataGrid>
  </div>
</template>

<script>
 import {DxDataGrid,
         DxColumn,
         DxScrolling,
         DxSorting,
         DxHeaderFilter,
         DxSearchPanel,
         DxExport} from "devextreme-vue/data-grid";
 import {DxSelectBox} from 'devextreme-vue/select-box';
 import dsu from "../../utils/dataSourceUtils";
 import fn from "../../utils/function";
 
 export default {
   components: {
     DxDataGrid,
     DxColumn,
     DxScrolling,
     DxSorting,
     DxHeaderFilter,
     DxSearchPanel,
     DxSelectBox,
     DxExport
   },
   data() {
     return {
       gridRefName: 'grid',
       dataSource: [],
       examList: [],
       selectedExam: {},
       examItemList: []
     };
   },
   beforeMount(){
     dsu.getAxiosExamList().then(res => {this.examList = res.data.data;});
   },
   computed: {
     grid: function (){return this.$refs[this.gridRefName].instance;}
   },
   methods:{
     onToolbarPreparing(e) {
       e.toolbarOptions.items.unshift(
         {location: 'before',
          locateInMenu: 'auto',
          template: 'examList-template',
         },
         {location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            text: '새로고침',
            stylingMode: "outlined",
            onClick: this.refreshData.bind()
         }}
       );
     },  
     refreshData(){
       this.dataSource = [];
       var params = {groupId: this.selectedExam.groupId};

       dsu.getAxiosOBJGet('/api/AEXI/selectExamItemInfo', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.examItemList = response.data;
          }else{
            fn.notifyError(response.msg);
          }
      });    

       dsu.getAxiosOBJGet('/api/AERJ/select', params).then(res => {
         this.dataSource = res.data.data;
       });
     },
     onSelectedExam(e){
       this.selectedExam = e.selectedItem;
       this.refreshData();
     },
     onExporting(e) {
          e.fileName = this.$route.meta.transName;
      }
   }
 };
</script>

<style lang="scss">
  #grid{
    width: 100%;
    height: calc(100vh - 210px);
  }
</style>