<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @focused-row-changed="selectedRowChanged($event)"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting">
      
      <DxSearchPanel :visible="false"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="false"/>
      <DxEditing :allow-updating="true"
                :allow-adding="true"
                :allow-deleting="true"
                mode="popup"/>
      
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
      
      <DxColumn
        data-field="id"
        caption="ID"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[A-Za-z0-9-]{1,20}$"
          message="영문 숫자 - 로만 구성, 20자 까지 등록 가능"
        />
      </DxColumn>

      <DxColumn
        data-field="userName"
        caption="이름"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{2,10}$"
          message="2~10자 까지 등록 가능"
        />
      </DxColumn>
      
      <DxColumn
        v-if="visibleAuth"  
        data-field="auth"
        caption="권한">  
        <DxRequiredRule/>
        <DxLookup :data-source="userAuthList"
                  value-expr="ID"
                  display-expr="Name"/>
      </DxColumn>

      <DxColumn
        v-if="visibleAuth"
        data-field="groupId"
        caption="심사 그룹">  
        <DxRequiredRule/>
        <DxLookup :data-source="examGroupList"
                  value-expr="groupId"
                  display-expr="groupNameId"
                  :allowClear="true"/>
      </DxColumn>      
    
      <DxMasterDetail
        :enabled="false"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/AUSR/selectHistory`"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import {userAuth,
          userAuthManager} from "../../utils/syscodedata";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import store from '../../store';

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/AUSR', 'id', '', 'ID'),
        userAuthList: [],
        examGroupList: [],
        visibleAuth: false, 
        selectedRowData: null,
        historyColumns: [{dataField : "transTime", caption : "변경 시간"},
                         {dataField: "userName", caption: "이름"},
                         {dataField: "auth", caption: "권한", lookup: {dataSource: userAuth, valueExpr: "ID", displayExpr: "Name"}},
                         {dataField: "groupId", caption: "심사 그룹", lookup: {dataSource: this.examGroupList, valueExpr: "groupId", displayExpr: "groupNameId"}},
                         {dataField: "password", caption: "비밀번호"},
                         {dataField: "userId", caption: "변경 사용자"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    mounted(){
      let auth = store.getters.getAuth;
      if (auth == 'ADMIN'){
        this.userAuthList = userAuth;
        this.visibleAuth = true;
      }else if(auth == 'MANAGER'){
        this.userAuthList = userAuthManager;
        this.visibleAuth = false;
      }

      dsu.getAxiosExamList({auth: auth}).then(res => {this.examGroupList = res.data.data;});
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'id'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"}  ;
        }
      },
      onRowInserting(e){
        e.data.id = e.data.id.toUpperCase();
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            text: '비밀번호 1234 로 초기화',
            stylingMode: "outlined",
            onClick: this.resetPassword.bind()
          }             
          
        });
      },selectedRowChanged(e){
        this.selectedRowData = e.row.data;
      },  
      resetPassword(){
        if(!this.selectedRowData){
          fn.notifyError('사용자 선택 후 진행 필요');
          return;
        }
        
        var params = {id: this.selectedRowData.id, password: '1234'};

        dsu.getAxiosOBJGet('/api/AUSR/update', params).then(res =>{
          let rtnMsg = res.data.msg;
          if(res.data.result == 'SUCCESS'){
            fn.notifySuccess('비밀번호 초기화 완료');
            this.$refs[this.gridRefName].instance.refresh(); 
          }else{
            fn.notifyError(rtnMsg);
          }        
        });
     }
    }
  };

</script>

<style scoped>
#grid{
  width: 100%;
  height: calc(100vh - 210px);
}
</style>