import store from './store';
import axios from 'axios';
import fn from './utils/function'

export default {
   
  async login(user){
    let rtnValue = false;

    await axios.post('/loginProc',
        '',
        {
          headers: { 'Content-Type': 'application/json; charset=UTF-8' },
          params: user
        }
    ).then(
        res => {
                  let data = res.data.data;
                  let result = res.data.result;
                  
                  if (result == "SUCCESS")
                  {
                    store.commit('setToken', data.token); 
                    store.commit('setUserId', data.userId);    
                    store.commit('setUserName', data.userName);   
                    store.commit('setAuth', data.auth);  
                    rtnValue = true;
                  }
                  else
                  {
                    fn.notifyError(data);
                  }
    }).catch(
        error => {
          fn.notifyError(error);
        }
    );

    return rtnValue;
  },
  async changePassword(userInfo){
    let rtnValue = false;
 
    let userId = store.getters.getUserId;
    let token = store.getters.getToken;
    let params = {userId: userId,
                  dmlUserId: userId,
                  newPassword: userInfo.new,
                  userPassword: userInfo.current};

    await axios.post('/api/changePassword',
        '',
        {
          headers: { 'Content-Type': 'application/json; charset=UTF-8', 'userId': userId, 'token': token, to: 'change-password' },
          params: params
        }
    ).then(
        res => {
                  let data = res.data.data;
                  let result = res.data.result;
                  
                  if (result == "SUCCESS")
                  {
                    rtnValue = true;
                    fn.notifySuccess("비밀번호 변경 성공");
                  }
                  else
                  {
                    rtnValue = false;
                    fn.notifyError(data);
                  }
    }).catch(
        error => {
          fn.notifyError(error);
        }
    );

    return rtnValue;
  },
  async loggedIn(to, updateExpireTime){
    let rtnValue = '';
    let token = store.getters.getToken;
    let params = {token: token
                 ,userId: store.getters.getUserId
                 ,to: to
                 ,updateExpireTime: updateExpireTime}

    if (token)
    {
        await axios.post('/loginCheck',
        '',
        {
          headers: { 'Content-Type': 'application/json; charset=UTF-8' },
          params: params
        }
        ).then(
            res => {
                let result = res.data.result;
                rtnValue = result;
                if (result != "SUCCESS" && result != "NEED_CHANGE_PASSWORD")
                {
                  this.logOut();
                }
        }).catch(
            error => {
              console.log(error);
              this.logOut();
              rtnValue = false;
            }
        );

        return rtnValue;
    }
  },
  async logOut() {
    await axios.post('/logout',
    '',
    {headers: {'Content-Type': 'application/json; charset=UTF-8'},
     params: {token: store.getters.getToken}}
    ).then(
        res => {
            let result = res.data.result;
            if (result == "SUCCESS"){
              store.commit('setToken', '');
              store.commit('setUserId', '');
              store.commit('setUserName', '');
              store.commit('setAuth', '');
              location.reload();
            }else{
              fn.notifyError(res.data.msg);
            }
    }).catch(
        error => {
          fn.notifyError(error);
        }
    );
  },
  async getUser() {
    try {
      return {
        isOk: true,
        data: {userId: store.getters.getUserId,
               userName: store.getters.getUserName
               }
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  }


};
