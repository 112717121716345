<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">

      <dx-item
        location="before"
        css-class="transName"
      >
        <template #default>
            <div class='description'>{{title}} </div> 
        </template>
      </dx-item>
      <dx-item
        location="after"
        locate-in-menu="auto">
        <DxButton text="새로고침"
                  icon="refresh"
                  template="refreshbutton-template"
                  @click="refreshTesterData()">

              <template #refreshbutton-template="{ data }">
                  <div class="buttonRefresh">
                    <img class="icon" src="../assets/refresh.png"/>
                    <div>{{ data.text }}</div>
                  </div>
              </template>  
        </DxButton>
      </dx-item>
      
      <dx-item
        location="after"
        locate-in-menu="auto">
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              height="100%"
              styling-mode="text"
            >
              <user-panel-judge :user="user" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { ref } from 'vue';
import UserPanelJudge from "./user-panel-judge";

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  created() {
    auth.getUser().then((e) => this.user = e.data);
  },
  methods:{
    refreshTesterData(){
      this.$emit('refreshTesterData');
    }
  },
  setup() {
    let user = ref({});
    const userMenuItems = [
        {
          text: "Logout",
          icon: "runner",
          onClick: onLogoutClick
        },
        {
          text: "Version : 0.1",
          icon: "info",
        }
    ];
      
    function onLogoutClick() {    
      auth.logOut();
    }
    return {
      user,
      userMenuItems
      
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanelJudge
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.transName
{
  .description
  {
    padding-left: 20px;
    font-size: 20px;
    font-weight: 700;
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.buttonRefresh{
  display:flex;
  align-items: center;
  justify-content: space-around;
  flex-direction:row;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 5px;
  
  .icon{
    width: 18px;
    height: 18px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
</style>
