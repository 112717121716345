import {alert,
        confirm} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import ds from './dataSourceUtils';

export default {
    notifySuccess(msg){
        notify({message: msg,  position: 'top'}, "success", 2000);
    },
    notifyError(msg){
        notify({message: msg,  position: 'top'}, "error", 2500);
    },
    alert(msg, title){
        if(title){
            alert(msg, title);
        }else{
            alert(msg, '결과');
        }
    },
    confirm(msg){
        return confirm(msg, '확인');
    },    
    printLotLabel(lotList){
        var params 

        if(Array.isArray(lotList)){
            params = {lotList: JSON.stringify(lotList)};
        }else{
            params = {lotList: JSON.stringify([lotList])};
        }
        ds.getAxiosOBJPost('/api/printLotLabel', params).then(res => {
            let response = res.data;

            if(response.result == 'SUCCESS'){
                this.notifySuccess(response.msg);
            }
            else{
                this.notifyError(response.msg);
            }
        });
    },
    printBatchLabel(batchId){
        var params = {batchId: batchId}; 
    
        ds.getAxiosOBJPost('/api/printBatchLabel', params).then(res => {
            let response = res.data;
            if(response.result != 'SUCCESS'){
              this.notifyError(response.msg);
            }
        });
    },
    downLoadFile(e){
        var columnName = e.column.dataField;
        if(columnName){
            if(columnName.toUpperCase().includes('FILE') && e.value){
                var filePath = e.data[columnName + 'Path'];
                window.location.href = '/fileDownload?file=' + encodeURIComponent(filePath);
            }
        }
    },
    getGridSubject(subject){
        return "▣ " + subject;
    }
}