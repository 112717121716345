<template>
  <div class="content-block dx-card responsive-paddings">
   <DxDataGrid
     id="grid"
     :ref="gridRefName"
     :data-source="dataSource"
     :column-auto-width="true"
     :show-borders="true"
     :show-column-lines="true"
     :focused-row-enabled="true"
     key-expr="num"
     @toolbar-preparing="onToolbarPreparing($event)"
     @init-new-row="onInitNewRow"
     @row-inserting="onRowInserting"
     @row-inserted="onRowInserted"
     @editing-start="onEditingStart"
     @saved="onSaved"
     @editCanceled="onEditCanceled"
     @row-removed="onRowRemoved">
     
     <DxSearchPanel :visible="false"/>
     <DxHeaderFilter :visible="true"/>
     <DxScrolling mode="virtual"/>
     <DxSorting mode="none"/>
     
     <DxEditing mode="popup"
               :allow-updating="true"
               :allow-adding="true"
               :allow-deleting="true">
       <DxForm :formData="formData">
        <DxSimpleItem dataField="testerName"/> 
        <DxSimpleItem dataField="fileId"
                       template="image-edit-template"/>
        <DxSimpleItem dataField="fileId2"
                       template="image-edit-template2"/>
       </DxForm>
     </DxEditing>

     <DxRowDragging
       :allow-reordering="true"
       :on-reorder="onReorder"
       :show-drag-icons="true"/>

     <DxColumn type="buttons">
       <DxButton name="edit"/>
     </DxColumn>

     <DxColumn
       data-field="num"
       caption="순서"
       :allowEditing="false"
       :header-filter="{ allowSearch: true }">
     </DxColumn>

     <DxColumn
       data-field="testerName"
       caption="이름"
       :header-filter="{ allowSearch: true }">
       <DxRequiredRule/>  
       <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{2,20}$"
          message="2~20자 까지 등록 가능"/>
     </DxColumn>

     <DxColumn
       data-field="fileId"
       caption="메인 이미지(가로 4 x 세로 4.5 비율이 좋음, 4mb 이하)"
       width="420px"
       :header-filter="{ allowSearch: false }"
       cellTemplate="image-cell-template">
     </DxColumn>

     <DxColumn
       data-field="fileId2"
       caption="심사 이미지(가로 4 x 세로 1.5 비율이 좋음, 4mb 이하)"
       width="420px"
       :header-filter="{ allowSearch: false }"
       cellTemplate="image-cell-template2">
     </DxColumn>

     <template #image-cell-template="{data}">
       <img class='tester' 
            :src="getImageURL(data.data.fileId)"/>
     </template>
     
     <template #image-cell-template2="{data}">
       <img class='tester' 
            :src="getImageURL(data.data.fileId2)"/>
     </template>

     <template #image-edit-template>
         <img v-if="selectedFileId" 
              class='posterInForm'
              :src="getImageURL(selectedFileId)"/>
         <DxFileUploader
           width="400"
           name="file"
           @beforeSend="onBeforeSend"
           @uploaded="onUploaded"
           upload-url="/api/AEXT/uploadTester"
           select-button-text="이미지 선택"
           accept="image/*"
           :max-file-size="4800000"/>
     </template>  

     <template #image-edit-template2>
         <img v-if="selectedFileId2" 
              class='testerInForm'
              :src="getImageURL(selectedFileId2)"/>
         <DxFileUploader
           width="400"
           name="file"
           @beforeSend="onBeforeSend"
           @uploaded="onUploaded2"
           upload-url="/api/AEXT/uploadTester"
           select-button-text="이미지 선택"
           accept="image/*"
           :max-file-size="4800000"/>
     </template>  


     <template #examList-template>
       <DxSelectBox
         :ref="selectBoxRefName"
         width="300"
         :items="examList"
         display-expr="groupNameId"
         value-expr="groupId"
         @selection-changed="onSelectedExam"/>
     </template>
   </DxDataGrid>
  </div>
</template>

<script>
 import {DxDataGrid,
         DxColumn,
         DxScrolling,
         DxSorting,
         DxHeaderFilter,
         DxEditing,
         DxSearchPanel,
         DxRequiredRule,
         DxRowDragging,
         DxForm,
         DxPatternRule} from "devextreme-vue/data-grid";
 import {DxSimpleItem} from 'devextreme-vue/form';
 import {DxSelectBox} from 'devextreme-vue/select-box';
 import {DxFileUploader} from "devextreme-vue/file-uploader";     
 import dsu from "../../utils/dataSourceUtils";
 import fn from "../../utils/function";

 export default {
   components: {
     DxDataGrid,
     DxColumn,
     DxScrolling,
     DxSorting,
     DxHeaderFilter,
     DxEditing,
     DxSearchPanel,
     DxRequiredRule,
     DxSelectBox,
     DxRowDragging,
     DxForm,
     DxSimpleItem,
     DxFileUploader,
     DxPatternRule
   },
   data() {
     return {
       gridRefName: 'grid',
       dataSource: [],
       examList: [],
       selectedExam: {},
       selectedFileId: '',
       selectedFileId2: '',
       formData: {},
       updateMode: false,
       selectBoxRefName: 'selectBox'
     };
   },
   beforeMount(){
     dsu.getAxiosExamList().then(res => {this.examList = res.data.data;});
   },
   computed: {
     grid: function (){return this.$refs[this.gridRefName].instance;}
   },
   methods:{
     onToolbarPreparing(e) {
       e.toolbarOptions.items.unshift(
         {location: 'before',
          locateInMenu: 'auto',
          template: 'examList-template',
         },
         {location: 'after',
           widget: 'dxButton',
           locateInMenu: 'auto',
           options: {
             text: '저장',
             icon: 'save',
             stylingMode:"outlined",
             onClick: this.save.bind(this)
         }},
         {location: 'after',
         widget: 'dxButton',
         locateInMenu: 'auto',
         options: {
           text: '삭제',
           icon: 'remove',
           stylingMode:"outlined",
           onClick: this.delete.bind(this)
         }}           
       );
     },
     onSelectedExam(e){
       this.selectedExam = e.selectedItem;
       this.refreshData();
     },
     onRowInserted(){
       const focusedRowIndex = this.grid.option("focusedRowIndex");
       const lastIndex = this.dataSource.length - 1;
      
       if(focusedRowIndex > -1) {
         const newDatas = [...this.dataSource];
         newDatas.splice(lastIndex, 1);
         newDatas.splice(focusedRowIndex + 1, 0, this.dataSource[lastIndex]);
         this.dataSource = newDatas;
       }
       this.makeNewNums();
     },
     onRowInserting(e){
       if(!this.selectedFileId){
         e.cancel = true;
         fn.notifyError("메인 이미지 필수 선택");
         return ;
       }
       
       e.data.fileId = this.selectedFileId;

       if(!this.selectedFileId2){
         e.cancel = true;
         fn.notifyError("심사 이미지 필수 선택");
         return ;
       }       
       e.data.fileId2 = this.selectedFileId2;
     },
     refreshData(){
       this.dataSource = [];
       var params = {groupId: this.selectedExam.groupId};
       
       dsu.getAxiosOBJGet('/api/AEXT/selectTesterInfo', params).then(res => {
           this.dataSource = res.data.data;
       });
     },
     makeNewNums(){
       for(let i=0; i<this.dataSource.length; i++){
         this.dataSource[i].num = i + 1;
       }
     },
     save(e){
       if(!this.selectedExam.groupId){
        fn.notifyError("심사 Group 선택 후 진행 필요");
         return;
       }

       if(this.dataSource.length < 1){
         fn.notifyError("하나 이상의 참가자 등록 후 저장 필요");
         return;
       }
       
       var params = {}
       params.groupId= this.selectedExam.groupId;
       params.testerList = JSON.stringify(this.dataSource);

       e.component.option('disabled', true);

       dsu.getAxiosOBJPost('/api/AEXT/updateTesterInfo', params).then(res => {
           let response = res.data;
           if(response.result == 'SUCCESS'){
             fn.notifySuccess(response.msg);
           }else {
             fn.notifyError(response.msg);
           }
           
           e.component.option('disabled', false);
       });
     },
     async delete(e){
       if(await fn.confirm(this.selectedExam.groupNameId + ' 의 모든 참가자를 삭제 하시겠습니까?') == false){
         return;
       } 

       var params = {}
       params.groupId = this.selectedExam.groupId;
       e.component.option('disabled', true);
      
       dsu.getAxiosOBJGet('/api/AEXT/deleteTesterInfo', params).then(res => {
           let response = res.data;
           if(response.result == 'SUCCESS'){
             fn.notifySuccess(response.msg);
             this.dataSource = [];
           }else {
             fn.notifyError(response.msg);
           }
           e.component.option('disabled', false);
       });
     },
     onBeforeSend(e){
       const headers = dsu.getHeaders();
       e.request.setRequestHeader('token', headers.token);
       e.request.setRequestHeader('userId', headers.userId);
     },
     onUploaded(e){
       const response = JSON.parse(e.request.responseText);

       if(response.result == 'SUCCESS'){
         e.message ="업로드 성공(File ID: " + response.msg + ")";
         this.selectedFileId = response.msg;
       }else{
         e.message ="업로드 실패"
         
       }
     },
     onUploaded2(e){
       const response = JSON.parse(e.request.responseText);

       if(response.result == 'SUCCESS'){
         e.message ="업로드 성공(File ID: " + response.msg + ")";
         this.selectedFileId2 = response.msg;
       }else{
         e.message ="업로드 실패"
         
       }
     },
     getImageURL(fileId){
       return encodeURI('/api/AEXT/displayTester?fileId='+ fileId);
     },
     onInitNewRow(){
       this.selectedFileId = '';
       this.selectedFileId2 = '';
     },
     onEditingStart(e){
       this.selectedFileId = e.data.fileId;
       this.selectedFileId2 = e.data.fileId2;
       this.updateMode = true;
     },
     onRowRemoved(){
       this.makeNewNums();
     },
     onReorder(e) {
       const visibleRows = e.component.getVisibleRows();
       const toIndex = this.dataSource.findIndex((item) => item.num === visibleRows[e.toIndex].data.num);
       const fromIndex = this.dataSource.findIndex((item) => item.num === e.itemData.num);
       const newDatas = [...this.dataSource];

       newDatas.splice(fromIndex, 1);
       newDatas.splice(toIndex, 0, e.itemData);

       this.dataSource = newDatas;
       this.makeNewNums();
     },
     onSaved(){
       if(this.updateMode){
         const focusedRowIndex = this.grid.option("focusedRowIndex");
         this.dataSource[focusedRowIndex].fileId = this.selectedFileId;
         this.dataSource[focusedRowIndex].fileId2 = this.selectedFileId2;
         this.updateMode = false;
       }
     },
     onEditCanceled(){
       this.updateMode = false;
     }
   }
 };
</script>

<style lang="scss">
  #grid{
    width: 100%;
    height: calc(100vh - 210px);
  }
  
 .tester{
   max-width: 400px;
 }

 .posterInForm{
   width: 100%;
 }
</style>