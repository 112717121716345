<template>
  <div class="content-block dx-card responsive-paddings">
   <DxDataGrid
     id="grid"
     :ref="gridRefName"
     :data-source="dataSource"
     :column-auto-width="true"
     :show-borders="true"
     :show-column-lines="true"
     :focused-row-enabled="true"
     key-expr="num"
     @toolbar-preparing="onToolbarPreparing($event)"
     @row-inserted="onRowInserted"
     @row-removed="onRowRemoved">
     
     <DxSearchPanel :visible="false"/>
     <DxHeaderFilter :visible="true"/>
     <DxScrolling mode="virtual"/>
     <DxSorting mode="none"/>
     
     <DxEditing mode="popup"
               :allow-updating="true"
               :allow-adding="true"
               :allow-deleting="true"/>

     <DxRowDragging
       :allow-reordering="true"
       :on-reorder="onReorder"
       :show-drag-icons="true"/>

     <DxColumn type="buttons">
       <DxButton name="edit"/>
     </DxColumn>

     <DxColumn
       data-field="num"
       caption="순서"
       :allowEditing="false"
       :header-filter="{ allowSearch: true }">
     </DxColumn>

     <DxColumn
      data-field="item"
      caption="심사 항목"
      :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>  
        <DxPatternRule
          pattern="^.{1,20}$"
          message="1~20자 까지 등록 가능"/>
     </DxColumn>

     <DxColumn
        data-field="score"
        caption="배점"
        data-type="number"
        :editorOptions="{min:1, max:100}"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>  
     </DxColumn>

     <template #examList-template>
       <DxSelectBox
         width="300"
         :items="examList"
         display-expr="groupNameId"
         value-expr="groupId"
         value="ALL"
         @selection-changed="onSelectedExam"/>
     </template>
   </DxDataGrid>
  </div>
</template>

<script>
 import {DxDataGrid,
         DxColumn,
         DxScrolling,
         DxSorting,
         DxHeaderFilter,
         DxEditing,
         DxSearchPanel,
         DxRequiredRule,
         DxRowDragging,
         DxPatternRule} from "devextreme-vue/data-grid";
  import {DxSelectBox} from 'devextreme-vue/select-box';
  import dsu from "../../utils/dataSourceUtils";
 import fn from "../../utils/function";

 export default {
   components: {
     DxDataGrid,
     DxColumn,
     DxScrolling,
     DxSorting,
     DxHeaderFilter,
     DxEditing,
     DxSearchPanel,
     DxRequiredRule,
     DxSelectBox,
     DxRowDragging,
     DxPatternRule
   },
   data() {
     return {
       gridRefName: 'grid',
       dataSource: [],
       examList: [],
       selectedExam: {},
       formData: {}
     };
   },
   beforeMount(){
     dsu.getAxiosExamList().then(res => {this.examList = res.data.data;});
   },
   computed: {
     grid: function (){return this.$refs[this.gridRefName].instance;}
   },
   methods:{
     onToolbarPreparing(e) {
       e.toolbarOptions.items.unshift(
         {location: 'before',
          locateInMenu: 'auto',
          template: 'examList-template',
         },
         {location: 'after',
           widget: 'dxButton',
           locateInMenu: 'auto',
           options: {
             text: '저장',
             icon: 'save',
             stylingMode:"outlined",
             onClick: this.save.bind(this)
         }},
         {location: 'after',
         widget: 'dxButton',
         locateInMenu: 'auto',
         options: {
           text: '삭제',
           icon: 'remove',
           stylingMode:"outlined",
           onClick: this.delete.bind(this)
         }}           
       );
     },
     onSelectedExam(e){
       this.selectedExam = e.selectedItem;
       this.refreshData();
     },
     onRowInserted(){
       const focusedRowIndex = this.grid.option("focusedRowIndex");
       const lastIndex = this.dataSource.length - 1;
      
       if(focusedRowIndex > -1) {
         const newDatas = [...this.dataSource];
         newDatas.splice(lastIndex, 1);
         newDatas.splice(focusedRowIndex + 1, 0, this.dataSource[lastIndex]);
         this.dataSource = newDatas;
       }
       this.makeNewNums();
     },
     refreshData(){
       this.dataSource = [];
       var params = {groupId: this.selectedExam.groupId};
       
       dsu.getAxiosOBJGet('/api/AEXI/selectExamItemInfo', params).then(res => {
           this.dataSource = res.data.data;
       });
     },
     makeNewNums(){
       for(let i=0; i<this.dataSource.length; i++){
         this.dataSource[i].num = i + 1;
       }
     },
     save(e){
       if(!this.selectedExam.groupId){
        fn.notifyError("심사 Group 선택 후 진행 필요");
         return;
       }

       if(this.dataSource.length < 1){
         fn.notifyError("하나 이상의 심사 항목 등록 후 저장 필요");
         return;
       }
       
       var params = {}
       params.groupId= this.selectedExam.groupId;
       params.examItemList = JSON.stringify(this.dataSource);

       e.component.option('disabled', true);

       dsu.getAxiosOBJPost('/api/AEXI/updateExamItemInfo', params).then(res => {
           let response = res.data;
           if(response.result == 'SUCCESS'){
             fn.notifySuccess(response.msg);
           }else {
             fn.notifyError(response.msg);
           }
           
           e.component.option('disabled', false);
       });
     },
     async delete(e){
       if(await fn.confirm(this.selectedExam.groupNameId + ' 의 모든 심사 항목을 삭제 하시겠습니까?') == false){
         return;
       } 

       var params = {}
       params.groupId = this.selectedExam.groupId;
       e.component.option('disabled', true);
      
       dsu.getAxiosOBJGet('/api/AEXI/deleteExamItemInfo', params).then(res => {
           let response = res.data;
           if(response.result == 'SUCCESS'){
             fn.notifySuccess(response.msg);
             this.dataSource = [];
           }else {
             fn.notifyError(response.msg);
           }
           e.component.option('disabled', false);
       });
     },
     onRowRemoved(){
       this.makeNewNums();
     },
     onReorder(e) {
       const visibleRows = e.component.getVisibleRows();
       const toIndex = this.dataSource.findIndex((item) => item.num === visibleRows[e.toIndex].data.num);
       const fromIndex = this.dataSource.findIndex((item) => item.num === e.itemData.num);
       const newDatas = [...this.dataSource];

       newDatas.splice(fromIndex, 1);
       newDatas.splice(toIndex, 0, e.itemData);

       this.dataSource = newDatas;
       this.makeNewNums();
     }
   }
 };
</script>

<style lang="scss">
  #grid{
    width: 100%;
    height: calc(100vh - 210px);
  }
</style>