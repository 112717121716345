<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @focused-row-changed="selectedRowChanged($event)"
      @editorPreparing="onEditorPreparing($event)"
      @init-new-row="onInitNewRow"
      @row-inserting="onRowInserting"
      @editing-start="onEditingStart"
      @editCanceled="onEditCanceled">
      
      <DxSearchPanel :visible="false"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxEditing :allow-updating="true"
                 :allow-adding="true"
                 :allow-deleting="true"
                 mode="popup">
        <DxForm>
          <DxSimpleItem dataField="groupId"/> 
          <DxSimpleItem dataField="groupName"/> 
          <DxSimpleItem dataField="fileId"
                        template="image-edit-template"/>
        </DxForm>
     </DxEditing>      
      
    <DxColumn type="buttons">
      <DxButton name="edit"/>
    </DxColumn>
      
      <DxColumn
        data-field="groupId"
        caption="Group ID"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[A-Za-z0-9-]{1,20}$"
          message="영문 숫자 - 로만 구성, 20자 까지 등록 가능"
        />
      </DxColumn>

      <DxColumn
        data-field="groupName"
        caption="Group Name"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{2,30}$"
          message="2~30자 까지 등록 가능"/>
      </DxColumn>
 
      <DxColumn
        data-field="fileId"
        caption="포스터"
        width="420px"
        :header-filter="{ allowSearch: false }"
        cellTemplate="image-cell-template">
      </DxColumn>

      <template #image-cell-template="{data}">
        <img v-if="data.data.fileId" 
             class='poster' 
             :src="getImageURL(data.data.fileId)"/>
      </template>

      <template #image-edit-template>
         <img v-if="selectedFileId" 
              class='posterInForm'
              :src="getImageURL(this.selectedFileId)"/>
         <DxFileUploader
           :ref="fileUploaderRefName"  
           width="400"
           name="file"
           @beforeSend="onBeforeSend"
           @uploaded="onUploaded"
           upload-url="/api/AEXG/uploadPoster"
           select-button-text="이미지 선택"
           accept="image/*"
           :max-file-size="4800000"/>
         <DxButton
           :width="120"
           text="이미지 삭제"
           type="normal"
           styling-mode="contained"
           @click="onClickDeleteFile($event)"/>
     </template>  

    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxForm} from "devextreme-vue/data-grid";
  import {DxSimpleItem} from 'devextreme-vue/form';
  import {DxFileUploader} from "devextreme-vue/file-uploader";     
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import DxButton from 'devextreme-vue/button';

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxForm,
      DxSimpleItem,
      DxFileUploader,
      DxButton
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/AEXG', 'groupId', '', '심사 Group ID'),
        selectedFileId: '',
        updateMode: false,
        currentData: {},
        fileUploaderRefName: 'fileUploader'
      };
    },
    computed: {
     grid: function (){return this.$refs[this.gridRefName].instance;},
     fileUploader: function (){return this.$refs[this.fileUploaderRefName].instance;}
    },
    methods:{
      onRowInserting(e){
        e.data.groupId = e.data.groupId.toUpperCase();
        // if(!this.selectedFileId){
        //   e.cancel = true;
        //   fn.notifyError("메인 이미지 필수 선택");
        //   return ;
        // }
        // e.data.fileId = this.selectedFileId;
        e.data.fileId = this.selectedFileId;
      },
      onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
          {location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            text: '점수 초기화',
            stylingMode: "outlined",
            onClick: this.resetScoreAll.bind()
          }}
        );
      },
      selectedRowChanged(e){
        this.selectedRowData = e.row.data;
      },  
      onEditorPreparing: function (e) { 
        if (e.dataField == 'groupId'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"}  ;
        }
      },
     async resetScoreAll(){
        if(!this.selectedRowData){
          fn.notifyError('대상 선택 후 진행 필요');
          return;
        }
                      
        if(await fn.confirm('정말 점수를 초기화 하시겠습니까?') == false){
          return;
        }
        
        var params = {groupId: this.selectedRowData.groupId};

        dsu.getAxiosOBJGet('/api/AEXG/resetScoreAll', params).then(res =>{
          let rtnMsg = res.data.msg;
          if(res.data.result == 'SUCCESS'){
            fn.notifySuccess('점수 초기화 완료');
            this.$refs[this.gridRefName].instance.refresh(); 
          }else{
            fn.notifyError(rtnMsg);
          }        
        });
     },
     getImageURL(fileId){
       return encodeURI('/api/AEXG/displayPoster?fileId='+ fileId);
     },
     onInitNewRow(){
       this.selectedFileId = '';
     },
     onEditingStart(e){
       this.selectedFileId = e.data.fileId;
       this.updateMode = true;
       this.currentData = e.data;
     },
     onBeforeSend(e){
       const headers = dsu.getHeaders();
       e.request.setRequestHeader('token', headers.token);
       e.request.setRequestHeader('userId', headers.userId);
     },
     onUploaded(e){
       const response = JSON.parse(e.request.responseText);

       if(response.result == 'SUCCESS'){
         e.message ="업로드 성공(File ID: " + response.msg + ")";
         this.selectedFileId = response.msg;
        if (this.updateMode){
          this.currentData.fileId = response.msg;
          this.dataSource.update(this.currentData.groupId, this.currentData);
        }
       }else{
         e.message ="업로드 실패"
         
       }
     },
     onEditCanceled(){
       this.updateMode = false;
     },
     onClickDeleteFile(){
       this.selectedFileId = '';
       this.currentData.fileId = '';
       this.dataSource.update(this.currentData.groupId, this.currentData);
       this.fileUploader.reset();
     }
    }
  };
</script>

<style scoped>
  #grid{
    width: 100%;
    height: calc(100vh - 210px);
  }
  .poster{
    max-width: 400px;
  }
  .posterInForm{
    width: 100%;
  }
</style>