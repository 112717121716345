import auth from "./auth";
import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import judgeLayout from "./layouts/judge";
import simpleLayout from "./layouts/single-card";
import ChangePassword from "./views/change-password"
import store from "./store";

import AUSR from "./views/_transaction/AUSR";
import AEXG from "./views/_transaction/AEXG";
import AEXT from "./views/_transaction/AEXT";
import AERJ from "./views/_transaction/AERJ";
import AERT from "./views/_transaction/AERT";
import URSC from "./views/_transaction/URSC";
import AEXI from "./views/_transaction/AEXI";


function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        transName: "Home",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/change-password",
      name: "change-password",
      meta: {
        transName: "비밀번호 변경",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ChangePassword
    },  
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    },
    {
      path: "/ausr",
      name: "ausr",
      meta: {
        transName: "사용자 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AUSR
    },
    {
      path: "/ausr2",
      name: "ausr2",
      meta: {
        transName: "심사위원 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AUSR
    },    
    {
      path: "/aexg",
      name: "aexg",
      meta: {
        transName: "심사 Group 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AEXG
    },
    {
      path: "/aext",
      name: "aext",
      meta: {
        transName: "참가자 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AEXT
    },
    {
      path: "/aexi",
      name: "aexi",
      meta: {
        transName: "심사항목 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AEXI
    },    
    {
      path: "/ursc",
      name: "ursc",
      meta: {
        transName: "평가",
        requiresAuth: true,
        layout: judgeLayout
      },
      component: URSC
    },
    {
      path: "/aerj",
      name: "aerj",
      meta: {
        transName: "심사 결과(심사위원)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AERJ
    },
    {
      path: "/aert",
      name: "aert",
      meta: {
        transName: "심사 결과(종합)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AERT
    }
  ]
});

router.beforeEach((to, from, next) => {
  auth.loggedIn(to.name, 'Y').then(loggedIn => {
      let auth = store.getters.getAuth;
      if (to.name === "login-form" && loggedIn == 'SUCCESS') {
        if(auth == "USER"){
          next({ name: "ursc" });
        }else{
          next({ name: "home" });
        }
      }
  
      if (to.matched.some(record => record.meta.requiresAuth)) {        
        if (loggedIn != 'SUCCESS') {
          next({ name: "login-form" });
        }else if(to.name == 'home'  &&  auth == 'USER'){
          next({ name: "ursc" });
        }else{
          next();
        }
      } else {
        next();
      } 
  });
});

export default router;
