import store from './store';

export default{
 
  getMenuItems()
  {
      let menuItem;
      let auth = store.getters.getAuth;

      let menuItemAdmin = {
        text: "마스터",
        icon: "preferences",
        items: [
          {text: "사용자 정보",
            path: "/ausr"},
          {text: "심사 Group 정보",
            path: "/aexg"},
          {text: "심사 항목 정보",
            path: "/aexi"},
          {text: "참가자 정보",
            path: "/aext"},
          {text: "심사 결과(심사위원)",
            path: "/aerj"},
          {text: "심사 결과(종합)",
            path: "/aert"}
        ]
      }  

      let menuItemManager = {
        text: "관리자",
        icon: "preferences",
        items: [
          {text: "심사위원 정보",
            path: "/ausr2"},
          {text: "심사 항목 정보",
            path: "/aexi"},
          {text: "참가자 정보",
            path: "/aext"},
          {text: "심사 결과(심사위원)",
            path: "/aerj"},
          {text: "심사 결과(종합)",
            path: "/aert"}
        ]
      }  
    
      menuItem = [
        {
           text: "바로가기",
           icon: "home",
           items: [{text: "HOME",
                    path: "/home"}]
        }
      ] 
   
      if(auth == 'ADMIN'){
        menuItem.push(menuItemAdmin); 
      }else if(auth == 'MANAGER'){
        menuItem.push(menuItemManager); 
      }
 
      return menuItem;
  }
}