<template>
  <HeaderToolBarJudge :title="currentExam.groupName"
                      @refreshTesterData="refreshTesterData"/>
  <DxScrollView
      id="scrollView">
      <DxForm :ref="formRefName">
        <DxColCountByScreen :xs="2" :sm="2" :md="2" :lg="2"/>
        <DxItem v-for="item in testerList" :key="item" template="tester-main-template" :dataField="item.num"/>
        <template #tester-main-template="{ data }">
            <div class="content-block dx-card responsive-paddings"
              @click="showScoreBoard(data.dataField)">
              <div class="testerMain">
                <img class="image" :src="getImageURL(testerList[data.dataField-1].fileId)"/>
                <div class="name">{{testerList[data.dataField-1].num}}. {{testerList[data.dataField-1].testerName}} ({{testerList[data.dataField-1].totalScore}})
                </div>
              </div>
            </div>
        </template>  
      </DxForm>
      <DxPopup        
        v-model:visible="popupVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :title="popupTitle"
        width="100%"
        height="100%">
        
        <DxToolbarItem location="after">
            <DxButton text="초기화"
                    icon="refresh"
                    template="resethbutton-template"
                    @click="clearScoreWithConfirm($event)">

                <template #resethbutton-template="{ data }">
                    <div class="buttonReset">
                      <img class="icon" src="../../assets/reset.png"/>
                      <div>{{ data.text }}</div>
                    </div>
                </template>  
            </DxButton>
          </DxToolbarItem>
     
          <div class="scoreBoard">
            <img class="imageTester" :src="getImageURL(this.currentTester.fileId2)"/>
            <DxScrollView :ref="scrollViewRefName">
            <DxForm :ref="formScoreRefName"
                    :element-attr="{class: 'scoreContainer'}"
                    colCount="1"
                    labelLocation="left"
                    :formData="formData"
                    @fieldDataChanged="onFieldDataChanged">

                    <DxSimpleItem v-for="(item, index) in examItemList"
                                :data-field="item.item"               
                                :key="'item' + (index + 1)"
                                editorType="dxNumberBox"
                                :editorOptions="{width: 220, min:0, max:item.score, inputAttr:{class:'inputScore'}, onValueChanged: this.onValueChanged, 
                                      buttons:[{name: 'minus', location:'before', options: getButtonOptionsMinus(item)},
                                               {name: 'plus', location:'after', options: getButtonOptionsPlus(item)}]}">
                      <DxLabel :text="JSON.stringify(item)"
                              alignment="right"
                              template="label-template"/>                      
                    </DxSimpleItem>

                    <DxSimpleItem dataField="scoreTotal"
                                  template="score-total-template">
                      <DxLabel text="총점"
                               alignment="center"
                               template="label-total-template"/>                                          
                    </DxSimpleItem>

                    <template #label-template="{data}">
                      <div class="label">
                        <div class="txt">{{JSON.parse(data.text).item}}</div>
                        <div class="sc">({{JSON.parse(data.text).score}})</div>
                      </div>
                    </template>
                    
                    <template #label-total-template="{data}">
                      <div class="labelTotal">{{data.text}}</div>
                    </template>
                    
                    <template #score-total-template>
                      <div class="total">
                        <div class="totalScore">{{scoreTotal}}</div>
                          <DxButton :ref="btnSaveRefName"
                                    :width="100"
                                    text="저장"
                                    :elementAttr="buttonSaveAttributes"
                                    @click="save($event)"/>
                      </div>
                    </template>
            </DxForm>
     
            </DxScrollView>
          
            <div class="navigation">
              <DxButton text="이전"
                        :elementAttr="buttonAttributes"
                        @click="showPrev()"/>
              <DxButton text="목록"
                        :elementAttr="buttonAttributes"
                        @click="showList()"/>                      
              <DxButton text="다음"
                        :elementAttr="buttonAttributes"
                        @click="showNext()"/>                                            
            </div>
          </div>
      </DxPopup>
      <DxPopup 
        v-if="this.currentExam.fileId"       
        :visible="popupVisiblePoster"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :title="currentExam.groupName"
        width="100%"
        height="100%">
        <div class="poster">
          <img class="image" :src="getImageURL(this.currentExam.fileId)"/>
          <DxButton text="심사하기"
                    :elementAttr="buttonAttributes"
                    class="hidePoster"
                    type="success"
                    styling-mode="contained"
                    @click="hidePoster()"/>             
        </div>
      </DxPopup>

  </DxScrollView>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import HeaderToolBarJudge from "../../components/header-toolbar-judge";
  import auth from "../../auth";
  import DxForm,{
         DxItem,
         DxSimpleItem,
         DxLabel,
         DxColCountByScreen} from "devextreme-vue/form"
  import DxPopup, 
         {DxToolbarItem}from 'devextreme-vue/popup';
  import DxButton from 'devextreme-vue/button';

  
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";

  export default {
    components: {
      DxScrollView,
      HeaderToolBarJudge,
      DxForm,
      DxItem,
      DxPopup,
      DxSimpleItem,
      DxLabel,
      DxButton,
      DxColCountByScreen,
      DxToolbarItem
    },
    data() {
      return {
        user: {},
        formRefName: 'form',
        formData: {},
        formScoreRefName: 'formScore',
        currentExam: '',
        currentTesterIdx: null,
        currentTester: {},
        testerList: [],
        popupVisible: false,
        popupTitle: '',
        scoreTotal: null,
        buttonAttributes: {class:'buttonExam'},
        buttonSaveAttributes: {class:'buttonSave'},
        popupVisiblePoster: true,
        examItemList: [],
        btnSaveRefName: 'btnSave',
        scrollViewRefName: 'sv',
      };
    },
    created() {
      auth.getUser().then((e) => this.user = e.data);
      this.refreshTesterData();
    },
    mounted(){
      setTimeout(() => {document.getElementById('scrollView').style.height = ((window.innerHeight - 75) + 'px');}, 500);
    },
    computed: {
      btnSave: function () { return this.$refs[this.btnSaveRefName].instance; },
      sv: function () { return this.$refs[this.scrollViewRefName].instance; },
    },
    methods:{
      refreshTesterData(){
        dsu.getAxiosOBJGet('/api/AEXG/select').then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            if (response.data.length == 0){
              fn.alert("<div class='alert-msg'>해당 심사위원의 심사 Group 정보가 없습니다.</div>", "정보");
              return;
            }

            this.currentExam = response.data[0];

            dsu.getAxiosOBJGet('/api/AEXT/selectTesterInfo', {groupId: this.currentExam.groupId}).then(res=>{
                let response = res.data;
                if(response.result == 'SUCCESS'){
                  this.testerList = response.data;
                }else{
                  fn.notifyError(response.msg);
                }
            });    

            dsu.getAxiosOBJGet('/api/AEXI/selectExamItemInfo', {groupId: this.currentExam.groupId}).then(res=>{
                let response = res.data;
                if(response.result == 'SUCCESS'){
                  this.examItemList = response.data;
                }else{
                  fn.notifyError(response.msg);
                }
            });    
          }else{
            fn.notifyError(response.msg);
          }
      });  
      },
      getImageURL(fileId){
       var imgUrl = "";
       if(fileId){
        imgUrl = encodeURI('/api/AEXT/displayTester?fileId='+ fileId);
       }
       return imgUrl
      },
      showScoreBoard(num) {
        this.sv.scrollTo(0,0);
        if(num < 1){
          fn.alert("<div class='alert-msg'>처음 참가자 입니다.</div>", "정보");
        }else if(num > this.testerList.length){
          fn.alert("<div class='alert-msg'>마지막 참가자 입니다.</div>", "정보");
        }else{
          this.clearScore();
          this.currentTesterNum = num;
          this.currentTester = this.testerList[this.currentTesterNum-1];
          this.popupTitle = this.currentTester.num + '. ' + this.currentTester.testerName;
          
          let params = {};
          params.groupId = this.currentExam.groupId;
          params.testerName = this.currentTester.testerName;
          this.btnSave.option('type','normal');
          dsu.getAxiosOBJGet('/api/URSC/selectExamResult', params).then(res=>{
            let response = res.data;
            let data = response.data;
            this.btnSave.option('type','normal');
            this.popupVisible = true;
            
            for (var i = 0 ; i < data.length ; i ++){
              this.formData[data[i].item] = data[i].score;
            }
          });    
        }

        
      },
      save(e){      
        var scoreList = [];  
        for(var i = 0 ; i < this.examItemList.length ; i++){
          let score = this.formData[this.examItemList[i].item];
          
          if(!score && score != 0){
            fn.alert("<div class='alert-msg'>모든 점수 입력 후 저장 부탁드립니다.</div>");
            return;
          }
          scoreList.push({item: this.examItemList[i].item, score: score});
        }

        var params = {};
        params.groupId = this.currentExam.groupId;
        params.testerName = this.currentTester.testerName;
        params.scoreList = JSON.stringify(scoreList);

        e.component.option('disabled', true);
        dsu.getAxiosOBJPost('/api/URSC/insertExamResult', params).then(res=>{
          let response = res.data;
          fn.alert("<div class='alert-msg'>" + response.msg + '</div>');
          if(response.result == 'SUCCESS'){
            this.btnSave.option('type','success');
          }

          e.component.option('disabled', false);
        });   
        
        this.refreshTesterData();
      },
      showPrev(){
        let num = this.currentTesterNum - 1;
        this.showScoreBoard(num);
        
      },
      showList(){
        this.currentTesterIdx = null;
        this.currentTester = {};
        this.popupVisible = false;
        this.popupTitle = null;
        this.clearScore();
      },
      showNext(){
        let num = this.currentTesterNum + 1;
        this.showScoreBoard(num);
      },
      clearScore(){
        for (var i = 0 ; i < this.examItemList.length ; i ++){
          this.formData[this.examItemList[i].item] = null;
        }
        this.scoreTotal = null;
      },
      async clearScoreWithConfirm(e){
        if(await fn.confirm("<div class='alert-msg'>정말 점수를 초기화 하시겠습니까?</div>") == false){
          return;
        }

        var params = {};
        params.groupId = this.currentExam.groupId;
        params.testerName = this.currentTester.testerName;

        e.component.option('disabled', true);
        dsu.getAxiosOBJPost('/api/URSC/deleteExamResult', params).then(res=>{
          let response = res.data;
          fn.alert("<div class='alert-msg'>" + response.msg + '</div>');
          e.component.option('disabled', false);
          this.clearScore();
          this.refreshTesterData();
        });   
      },
      onValueChanged(){
          this.scoreTotal = 0 ;
          this.examItemList.forEach(item => {
            let score = this.formData[item.item];
            if(score){
              this.scoreTotal += score;
            }
          });
      },
      hidePoster(){
        this.popupVisiblePoster = false;
      },
      getButtonOptionsMinus(item){
          return {text:'-', 
                  elementAttr: {class: 'btnScorePM'},
                  onClick: () => {
                    let score = this.formData[item.item];
                    if (!score){
                      score = 0;
                    }
                    score = score - 5;
                    if (score < 0){
                      score = 0;
                    }
                    this.formData[item.item] = score;
                  }
          };
      },
      getButtonOptionsPlus(item){
          return {text:'+', 
                  elementAttr: {class: 'btnScorePM'},
                  onClick: () => {
                    let score = this.formData[item.item];
                    if (!score){
                      score = 0;
                    }
                    score = score + 5;
                    if (score > item.score){
                      score = item.score;
                    }
                    this.formData[item.item] = score;
                  }
          };
      },
      onFieldDataChanged(){
        this.btnSave.option('type','normal');
      }
    }
  };

</script>

<style lang="scss">
  #scrollView{
    width: 100%;
    height: calc(100vh - 75px);
  }
  .testerMain{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image{
      width: 100%;
      border-radius: 10px;
    }

    .name{
      width: 100%;
      margin-top: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
      border-radius: 10px;
      background-color: rgb(239, 239, 239);
      text-align: center;
      font-weight: bold;
      font-size: 27px;
    }
  }
  
  .scoreBoard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .imageTester{
      width: 100%;
      height: 40%;
      border-radius: 10px;
      box-shadow:3px 3px 3px rgba(235, 235, 235);
    }
        
    .scoreContainer{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(235, 235, 235, 0.4); 
      border-radius: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      box-shadow:3px 3px 3px rgba(235, 235, 235);
    }

    .navigation{
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      background-color: rgba(235, 235, 235, 0.4); 
      border-radius: 10px;
      padding: 20px;
      box-shadow:3px 3px 3px rgba(235, 235, 235);
    }
  }
 
  .label{
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: bold;
    
    .txt{
      word-break: break-all;
      text-align: right;
      margin-right: 15px;
    }
    .sc{
      width: 30px;
      text-align: center;
      margin-right: 15px;
    }
  }

  .inputScore{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  
  .labelTotal{
    width: 130px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;    
  }
  
  .total{
    display: flex;
    width: 325px;
    flex-direction: row;
    align-items: center;
    .totalScore{
      width: 220px;
      text-align: center;
      font-size: 26px;
      font-weight: bold;    
    }
  }

  .buttonSave{
    width: 120px;
    height: auto;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    margin-left: 35px;
  }

  .buttonExam{
    width: 120px;
    height: auto;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
  }

  .poster{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .image{
      height: 90%;
      border-radius: 10px;
    }
    
    .hidePoster{
      margin-bottom: 3%;
    }
  }

  .buttonReset{
    display:flex;
    align-items: center;
    justify-content: space-around;
    flex-direction:row;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    margin-right: 5px;
    
    .icon{
      width: 30px;
      height: 30px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .btnScorePM{
    font-size: 23px;
    font-weight: bold;
  }

  .alert-msg{
    font-size: 20px;
  }

</style>