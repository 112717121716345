import 'whatwg-fetch';

export const userAuth = [
  {ID: 'ADMIN', Name: '마스터'},
  {ID: 'MANAGER', Name: '관리자'},
  {ID: 'USER', Name: '심사위원'}
];

export const userAuthManager = [
  {ID: 'USER', Name: '심사위원'}
];